<template>
  <div style="background-color: #DFDFDF;">
  <v-container class="py-8" >
    <v-row class="my-10">
      <v-col cols="12" class="text-center">
        <h3 
          class="text-h3 font-italic font-weight-bold my-6 txt-secondary"
        >
          TODO PARA TU OBRA A UN CLICK
        </h3>
        <h4
          class="txt-primary text-h4 font-italic my-4"
        >
          Categorías
        </h4>
        <p>
          Encuentra el producto que buscas por categoría:
        </p>
      </v-col>
    </v-row>
    <v-row
      class="text-center my-4"
      justify="center"
    >
      <v-col
        v-for="categoria in categories"
        :key="categoria.id"
        cols="12"
        class="mx-4"
        sm="4"
        md="1"
      >
        <!-- <img
          :src="categoria.imagen"
          width="100"
        />
        <img
          :src="categoria.imagen_alt"
          width="100"
        /> -->
        <v-btn
          class="mx-2 my-2"
          fab
          min-width="100"
          min-height="100"
          :to="{name: 'Productos', query: { filtro: categoria.dato }}"
        >
          <v-hover>
            <img
              slot-scope="{ hover }"
              v-if="hover"
              :src="categoria.imagen_alt"
              width="100"
            />
            <img
              v-else
              :src="categoria.imagen"
              width="100"
            />
          </v-hover>
        </v-btn>
      </v-col>

    </v-row>
  </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('products', ['categories'])
  },
}
</script>
<style scoped>
.img-style {
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
}
.v-btn {
  min-width: 100px;
  width: 100px;
}
</style>
